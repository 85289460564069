h2 {
  margin-top: 2rem;
}

.article-list.card {
  border-radius: 10px !important;
  cursor: pointer;
}

.article-list .article-list-vertical {
  background: black;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 99.9%);
  background-size: 100% 100%;
}